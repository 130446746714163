.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.05); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's on top */
}

.loader {
  width: 80px;
  height: 80px;
  border: 5px solid rgba(255, 255, 255, 0.5);
  border-top: 5px solid #8f8f8f;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
.backdrop p{
  color: #5c5c5c;
  transform: translateX(10px);
  font-size: 25px;
  font-weight: bold;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
