@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lilita+One&family=Oswald:wght@200..700&display=swap");

.App {
  text-align: center;
}
body {
  padding: 0;
  font-family: "Poppins", sans-serif;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.leaflet-container {
  width: 100%;
  height: calc(100vh - 80px);
  background: #fff;
}

.leaflet-control-container .leaflet-routing-container-hide {
  display: none;
}

.leaflet-left {
  margin-top: 70px;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 7px;
  opacity: 0;
}
:hover::-webkit-scrollbar {
  opacity: 1;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ddf2fd;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9bbec8;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #427d9d;
}

.top-navbar-logo {
  font-size: 18px;
  color: #ddf2fd;
  font-weight: 600;
  margin-right: 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.form-group label {
  font-weight: 600;
  margin-bottom: 5px;
}

.form-inline {
  display: flex;
  align-items: center;
}

.form-inline > button {
  border: 1px solid #000;
  background-color: #000;
  color: #fff;
  padding: 15px 20px;
  font-size: 14px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

/* TopNavbar.css */
.top-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 15px 20px;
  background-color: #254336;
  /* background-color: #164863; */
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999999999;
}

.top-navbar-logo a {
  color: #fff;
  text-decoration: nrgb(29, 29, 31);
  font-size: 1.5rem;
}

.top-navbar-links a {
  color: #fff;
  text-decoration: none;
  margin-left: 20px;
}

.top-navbar-links a:hover {
  text-decoration: underline;
}
.main-content {
  position: relative;
  width: 100%;
}
.main-content button:nth-child(3) {
  /* float: left; */
  top: 120px;
  /* margin-top: 130px; */
  /* margin-left: 18px; */
}
/* .tl-nav-wrap {
  display: flex;
  align-items: center;
} */
/* form.main-search {
  position: relative;
  display: inline-block;
  left: 0;
  right: 0;
  z-index: 99999;
  max-width: 80%;
  margin: 0 auto;
  width: 400px;
} */

.main-search {
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
}

.form-inline {
  position: relative;
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 25px;
  padding: 5px 10px;
  /* width: 500px; */
  background-color: #6b8a7a;
}
input[type="text"] {
  border: none;
  outline: none;
  flex: 1;
  padding-left: 10px;
}
.category-button {
  border-radius: 25px;
  /* border-bottom-left-radius:10px ; */
  background-color: #254336;
  padding: 10px 25px;
  margin-right: 5px;
}
.category-tag {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  /* background-color: #4caf50; */
  background-color: #254336;
  color: white;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 14px;
  height: 30px;
}

.main-search .search-btn {
  /* border: 1px solid #427d9d; */
  border: none;
  /* background-color: #427d9d; */
  background-color: #6b8a7a;
  /* background-image: url("./assets/search-interface-symbol.png"); */
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  border-left: 0;
  padding: 15px 30px;
  height: 43px;
  background-size: 16px;
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
}

.main-search .form-inline input {
  border-right: 0;
  /* border-radius: 0 !important; */
  /* border: 1px solid #427d9d !important; */
  border: none;
  font-size: 14px;
  padding: 10px 15px !important;
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
  /* width: 100%; */
  /* background-color: #427d9d; */
  background-color: #6b8a7a;
  color: #ddf2fd;
}
.main-search .form-inline input::placeholder {
  color: #ddf2fd;
}
.sub-sidebar-wrap ul {
  list-style-type: none;
  padding-left: 0;
}
.sub-sidebar-wrap ul li {
  text-align: left;
}

.formcheck {
  text-decoration: none;
  list-style-type: none;
  white-space: inherit;
  padding-left: 0;
  text-align: left;
  margin: 10px; /* Add this line to remove extra space */
  padding: 50; /* Add this line to remove extra space */
  width: 15vh;
}

.formcheck a {
  text-decoration: none;
  color: #1b7fb4;
  /* margin: 10px; */
}

/* .formcheck .active{
  color: #164863;
  background-color: rgb(66 125 157 / 30%);
} */

.sidebar-overflow {
  height: 87vh;
  overflow: scroll;
  /* padding:0; */
}

.sidebar-overflow ul {
  list-style-type: inherit;
  padding: 0;
  margin: 0;
}
.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}
.app {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
}
.select-element select {
  width: 100%;
  border-radius: 5px;
  padding: 5px;
}
.btn-secondary {
  border: 1px solid #d5e6ff;
  border-radius: 30px;
  font-size: 14px;
  background-color: #fff;
  color: #404040;
  display: inline-block;
  padding: 10px 25px;
  transition: all 300ms ease-in-out;
}
.btn-secondary:hover {
  border-color: #ff6961;
}

.leaflet-draw-toolbar a {
  font-size: 50px;
  width: 50px; /* Adjust width */
  height: 50px; /* Adjust height */
  /* background-size: 24px 24px; Adjust background size */
}
.leaflet-top .leaflet-draw {
  margin-top: 110px;
  margin-left: 10px;
}
/* .leaflet-top .leaflet-draw .leaflet-draw-section{

}
/* Remove background and border from Leaflet controls */
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  background-color: transparent;
  border: none;
}

/* Style for the draw toolbar buttons */
.leaflet-top .leaflet-draw .leaflet-draw-section .leaflet-draw-toolbar a {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 4px;
  margin-top: 10px;
  background-color: #fff;
  padding: 5px;
}

/* Styling for the first draw section */
.leaflet-top .leaflet-draw .leaflet-draw-section:nth-child(1) {
  padding: 5px;
  background-color: #6b8a7a;
  border-radius: 10%;
}

/* Styling for the first draw section */
.leaflet-top .leaflet-draw .leaflet-draw-section:nth-child(2) {
  padding: 5px;
  background-color: #6b8a7a;
  border-radius: 10%;
  margin-top: 10px;
}

.leaflet-draw-actions a {
  color: #555353;
}
.leaflet-touch .leaflet-draw-actions {
  border: 1px solid #0000002e;
  border-radius: 5px;
  text-align: center;
  transform: translateY(-15px);
  left: 60px;
}
.leaflet-touch .leaflet-draw-actions a {
  line-height: 20px;
}

.leaflet-top .leaflet-draw .leaflet-draw-section:nth-child(1) a {
  margin-top: 10px;
  padding: 5px;
  background-color: #fff;
  transform: translateY(-5px);
}

/* Styling for the second draw section */
.leaflet-top .leaflet-draw .leaflet-draw-section:nth-child(2) a {
  margin-top: 10px;
  padding: 5px;
  background-color: #fff;
  transform: translateY(-5px);
}

/* Style for all draw toolbar buttons */
.leaflet-touch .leaflet-draw-toolbar a {
  /* background-color: #fff; */
  z-index: 1000;
  background-repeat: no-repeat;
}

/* Simplified toolbar button styling */
.leaflet-draw-toolbar a {
  display: inline-block;
  margin-right: 5px;
  color: red; /* Customize the text color */
  font-weight: bold;
}

/* Retina support for toolbar buttons */
/* .leaflet-retina .leaflet-draw-toolbar a {
  background-image: url('../public/lineSVG.svg'); 
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative; x
  z-index: 1000;
} */

.disabled {
  pointer-events: none;
  color: gray;
  cursor: not-allowed;
}

.link-flex {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  /* background-color: blue; */
}

.categories-dropdown {
  position: absolute;
  top: 90px;
  left: 40%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 5px; /* Add unit for gap */
  width: 500px;
  color: #000;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  animation-name: bounceDownIn; /* Name of the animation */
  animation-duration: 0.5s;
}






.categories-dropdown div {
  /* border: 1PX solid black; */
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  padding: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.categories-dropdown div:hover {
  background: #f0f0f0;
}

.search-btn {
  margin-left: 10px;
  cursor: pointer;
}

@keyframes bounceDownIn {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  50% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.suggestion-item {
  position: absolute;
  /* top:100px; */
  /* left:20%; */

  gap: 5px; /* Add unit for gap */
  width: 500px;
  color: #000;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  animation-name: bounceDownIn; /* Name of the animation */
  animation-duration: 0.5s;
}

.suggestions-container ul {
  list-style: none;
}

.suggestions-container ul li {
  width: 200px;
  /* background-color: blue; */
  margin-left: -50px;
  margin-top: 20px;
}

.leaflet-interactive .custom-drop-shadow {
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));
}

.propertyform {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-out;
}

.propertyform.open {
  height: 100%;
  overflow: hidden;
  transition: height 0.3s ease-in;
}

.polygon-label {
  background-color: transparent;
  /* border: 1px solid black; */
  border-radius: 5px;
  padding: 5px;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(240, 236, 236, 0.5);
  /* color:white */
}

.icon-container {
  display: inline-block;
  transition: transform 1s ease; /* Smooth rotation transition */
}

.animate {
  animation: rotate 1s ease forwards;
}
/* 
@keyframes vibrate {
  0% {
    transform: translateX(-4px);
  }
  50% {
    transform: translateX(4px);
  }
  100% {
    transform: translateX(-5px);
  }
} */

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

.custom-marker {
  width: 20px;
  height: 20px;
  background-color: #ffd700; /* Yellow for visibility */
  border: 2px solid #000000; /* Black border for contrast */
  border-radius: 50%; /* Circular shape */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

/* Custom label style */
.custom-tooltip {
  font-size: 14px;
  font-weight: bold;
  color: #00008b; /* Dark Blue for readability */
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white */
  padding: 4px 6px;
  border-radius: 5px;
  border: 1px solid #333;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}


   /* Custom style for round green corner points */
   .leaflet-div-icon {
    background:#17A2B8 !important;
  
    width: 15px !important;
    height: 15px !important;
    border-radius: 50% !important;
    border: 1px solid black !important;
}

