/* Modal container */
.modal-overlay {
    position: fixed;
    top: 230px;
    left: 350px;
    /* width: 100%; */
    /* height: 100%; */
    /* background-color: rgba(0, 0, 0, 0.5); */
    /* border: 10px solid black;; */
    display: flex;
    /* justify-content: space-evenly; */
    /* align-items: center; */
    z-index: 1000001;
  }
  
  .modal-overlay .modal-content {
    background-color: rgb(255, 255, 255);
    /* border:20px solid black; */

    border-radius: 5px;
    width: 250px;
    max-width: 300px;
    /* height: 550px; */
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    animation: bounceRight 0.5s forwards;
  }
  
  /* Animation for sliding in from the left and bouncing */
  @keyframes bounceRight {
    0% {
      transform: translateX(-100%);
    }
    60% {
      transform: translateX(20px);
    }
    80% {
      transform: translateX(-10px);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  /* Close button */
  .modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 20px;
    cursor: pointer;
  }


  
.modal-overlay .modal-content .container .card .card-body{

  text-align: left;
}



/* Drop Modal css  */

@keyframes vibrate {
  0% { transform: translate(0); }
  25% { transform: translate(-2px, 0); }
  50% { transform: translate(2px, 0); }
  75% { transform: translate(-2px, 0); }
  100% { transform: translate(0); }
}

.vibrate {
  animation: vibrate 0.5s ease-in-out;
}

.modal-upload-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.dropzone {
  height:120px;
  margin-bottom: 15px;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.dropzone .active {
  border-color: green;
}

.uploaded-files-list {
  display: 'flex';
  flex-wrap: 'no-wrap';
  margin-top: 15px;
}

.uploaded-files-list ul {
  list-style: none;
  
}

.uploaded-files-list ul li {

  margin:0;
  padding: 0;
}